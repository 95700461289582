import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  animations: [
    trigger('panelInOut', [
        transition('void => *', [
            style({transform: 'translateY(100%)'}),
            animate(400)
        ]),
        transition('* => void', [
            animate(400, style({transform: 'translateY(100%)'}))
        ])
    ])
]
})
export class NavbarComponent implements OnInit {

  constructor(private router: Router) { }

  route: string = undefined;
  
  showMobileServicesSelector: boolean = false;

  ngOnInit(): void {
    this.setRouterEventListener();
  }

  setRouterEventListener(): void {
    this.route = this.router.url;
    if(this.route.includes('/services')) this.route = '/services';
  }

  openService(service: string): void {
    this.router.navigate(['/services'], {queryParams: {'service': service}});
    if(this.route == '/services') setTimeout(() => {window.location.reload();}, 300);
  }

  openServices(): void {
    const screenWidth = screen.width;
    (screenWidth <= 992) ? this.showMobileServicesSelector = true : this.router.navigate(['/services']);
  }

}
