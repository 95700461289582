<app-navbar></app-navbar>
<div class="main-section">
    <div class="bg-image" style="background-image: url('../../../assets/bg_image_3.jpg')">
        <div class="centered-bg-content">
            <div class="glassmorphism-container">
                <p class="big-title">Запази си час</p>
                <p class="big-description">на телефон 098 822 5036</p>
            </div>
            <!--
            <div class="reservation-container">
                <div class="reservation-item-container">
                    <p class="reservation-item-title">Какво желаете?</p>
                    <select class="reservation-item-input">
                        <option value="1" selected>Тест</option>
                        <option value="2">Тест</option>
                        <option value="3">Тест</option>
                    </select>
                </div>
                <div class="reservation-item-container">
                    <p class="reservation-item-title">Избери услуга</p>
                    <select class="reservation-item-input">
                        <option value="1" selected>Тест</option>
                        <option value="2">Тест</option>
                        <option value="3">Тест</option>
                    </select>
                </div>
                <div class="reservation-item-container">
                    <p class="reservation-item-title">Кога?</p>
                    <div class="reservation-item-two-inputs">
                        <input type="date" id="date" class="reservation-item-input" />
                        <input type="time" id="time" value="09:00:00" step="900" class="reservation-item-input" />
                    </div>
                </div>
            </div>
            <div class="reservation-container-mobile">
                <div class="reservation-item-container">
                    <p class="reservation-item-title">Какво желаете?</p>
                    <select class="reservation-item-input">
                        <option value="1" selected>Тест</option>
                        <option value="2">Тест</option>
                        <option value="3">Тест</option>
                    </select>
                </div>
                <div class="reservation-item-container">
                    <p class="reservation-item-title">Избери услуга</p>
                    <select class="reservation-item-input">
                        <option value="1" selected>Тест</option>
                        <option value="2">Тест</option>
                        <option value="3">Тест</option>
                    </select>
                </div>
                <div class="reservation-item-container">
                    <p class="reservation-item-title">Кога?</p>
                    <div class="reservation-item-two-inputs">
                        <input type="date" id="dateMobile" class="reservation-item-input" />
                        <input type="time" id="timeMobile" value="09:00:00" step="900" class="reservation-item-input" />
                    </div>
                </div>
            </div>
            <button class="button action-button">Запази</button>
            -->
        </div>
    </div>
    <div class="welcome-container">
        <p class="medium-title black-text">Beauty Studio Desita</p>
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="welcome-image" style="background-image: url('../../../assets/bg_image_4.jpg')"></div>
                </div>
                <div class="col-lg-6 welcome-side">
                    <p class="welcome-description">Beauty Studio Desita отвори врати за Вас, въвеждайки Ви в света, в който всички фантазии по преобразяването на външността Ви могат да станат реалност. Студиото предлага фризьорство, козметика, маникюр, педикюр, солариум, грим, масажи, миглопластика, лазерна епилация и микроблейдинг. Ако искате да се насладите на приятна обстановка и професионализъм, това е точното място.Посещението при нас ще Ви подари мигове на абсолютен релакс, в луксозната обстановка на салона, под специалните грижи и внимание на нашите професионалисти.</p>
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="counter-container">
        <p class="medium-title black-text">Какво казват цифрите?</p>
        <div class="container">
            <div class="row">
                <div class="col-sm">
                    <div class="counter-section">
                        <p class="small-text gray-text uppercase-text">Над</p>
                        <p class="counter-text">2500</p>
                        <p class="small-text primary-text uppercase-text">щастливи клиенти</p>
                    </div>
                </div>
                <div class="col-sm">
                    <div class="counter-section">
                        <p class="small-text gray-text uppercase-text">Повече от</p>
                        <p class="counter-text">20</p>
                        <p class="small-text primary-text uppercase-text">години опит</p>
                    </div>
                </div>
                <div class="col-sm">
                    <div class="counter-section">
                        <p class="small-text gray-text uppercase-text">Над</p>
                        <p class="counter-text">10000</p>
                        <p class="small-text primary-text uppercase-text">изпълнени процедури</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="feedback-container">
        <p class="medium-title black-text">Какво казват клиентите?</p>
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <div class="column-profile-picture" style="background-image: url('../../../assets/feedback/1.jpg')"></div>
                    <p class="column-title">Ваня Борисова</p>
                    <p class="column-description">Уникално място и обстановка. Стилисти правещи вълшебства. БЛАГОДАРЯ!!!</p>
                </div>
                <div class="col-md-4">
                    <div class="column-profile-picture" style="background-image: url('../../../assets/feedback/2.jpg')"></div>
                    <p class="column-title">Стилияна Николова</p>
                    <p class="column-description">Страхотно място !!! Предлагат се много услуги,персонала е много любезен,а цените са много добре подбрани ! Няма да съжалявате. 🙂</p>
                </div>
                <div class="col-md-4">
                    <div class="column-profile-picture" style="background-image: url('../../../assets/feedback/4.jpg')"></div>
                    <p class="column-title">Мария Генова</p>
                    <p class="column-description">Невероятно професионалисти, със страхотен подход към всеки,много разбрани и отговорни! Щастлива съм, че ми помогнаха да върна усмивката си!</p>
                </div>
            </div>
        </div>
    </div>
    <div class="services-container">
        <p class="medium-title black-text">Услугите ни</p>
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-5 col-sm-12 service-image-container">
                    <div class="mobile-service-image-content-container">
                        <p class="service-title">Фризьорство</p>
                        <button class="action-button" routerLink="/services" [queryParams]="{'service': 'friziorstvo'}">Научи повече</button>
                    </div>
                    <div style="background-image: url('../../../assets/services_bg/frizior.jpg');" class="service-image">
                    </div>
                </div>
                <div class="col-lg-8 col-md-7 col-sm-12 service-content-container">
                    <p class="service-title">Фризьорство</p>
                    <p class="service-description">Здравата коса е красива коса!
                        Екипът ни от трима професионални коафьори ще ви предложи конкретна насоченост според Вашите изисквания. Салонът работи с висококачествените продукти на марките Christian of Roma, Milkshake, Alfaparf, Papino, Nook и други. Ние вярваме, че занаята, с който се занимаваме, изисква отдаденост на 100%, непрекъснато обучение и усъвършенстване. Искаме да дадем най-доброто на нашите клиенти, те са специални за нас!
                        </p>
                    <button class="action-button" routerLink="/services" [queryParams]="{'service': 'friziorstvo'}">Научи повече</button>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-md-7 col-sm-12 service-content-container">
                    <p class="service-title">Козметика</p>
                    <p class="service-description">Кожата е най-големият орган от човешкото тяло. Изисква специално отношение и внимателно третиране. Да се грижиш за кожата е отговорност, която ние в Beauty Studio Desita приемаме много сериозно!
                        </p>
                    <button class="action-button" routerLink="/services" [queryParams]="{'service': 'kozmetika'}">Научи повече</button>
                </div>
                <div class="col-lg-4 col-md-5 col-sm-12 service-image-container">
                    <div class="mobile-service-image-content-container">
                        <p class="service-title">Козметика</p>
                        <button class="action-button" routerLink="/services" [queryParams]="{'service': 'kozmetika'}">Научи повече</button>
                    </div>
                    <div style="background-image: url('../../../assets/services_bg/kozmetika.jpg');" class="service-image">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-5 col-sm-12 service-image-container">
                    <div class="mobile-service-image-content-container">
                        <p class="service-title">Маникюр и педикюр</p>
                        <button class="action-button" routerLink="/services" [queryParams]="{'service': 'manikiur-i-pedikiur'}">Научи повече</button>
                    </div>
                    <div style="background-image: url('../../../assets/services_bg/manikur.jpg');" class="service-image">
                    </div>
                </div>
                <div class="col-lg-8 col-md-7 col-sm-12 service-content-container">
                    <p class="service-title">Маникюр и педикюр</p>
                    <p class="service-description">Перфектните маникюр и педикюр са визитката на една дама! Независимо дали ще се спрете на класически цветове, френски маникюр или пък на ефектни декорации, изграждане с гел – от нас ще си тръгнете с настроение и самочувствие. А безупречните резултати са гарантирани от партньорите ни KODI,BLUESKY,SNB и GEL.IT
                        </p>
                    <button class="action-button" routerLink="/services" [queryParams]="{'service': 'manikiur-i-pedikiur'}">Научи повече</button>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-md-7 col-sm-12 service-content-container">
                    <p class="service-title">Лазерна епилация</p>
                    <p class="service-description">Работим с диоден лазер от най-висок клас и процедурите са ефективни за всички фототипове кожа, и напълно безопасни по всяко време на годината. Диодната лазерна епилация е доказано най-ефективният метод за трайно обезкосмяване.
                        </p>
                    <button class="action-button" routerLink="/services" [queryParams]="{'service': 'lazerna-epilaciq'}">Научи повече</button>
                </div>
                <div class="col-lg-4 col-md-5 col-sm-12 service-image-container">
                    <div class="mobile-service-image-content-container">
                        <p class="service-title">Лазерна епилация</p>
                        <button class="action-button" routerLink="/services" [queryParams]="{'service': 'lazerna-epilaciq'}">Научи повече</button>
                    </div>
                    <div style="background-image: url('../../../assets/services_bg/lazerna_epilaciq.jpg');" class="service-image">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-5 col-sm-12 service-image-container">
                    <div class="mobile-service-image-content-container">
                        <p class="service-title">Солариум</p>
                        <button class="action-button" routerLink="/services" [queryParams]="{'service': 'solarium'}">Научи повече</button>
                    </div>
                    <div style="background-image: url('../../../assets/services_bg/solarium.jpg');" class="service-image">
                    </div>
                </div>
                <div class="col-lg-8 col-md-7 col-sm-12 service-content-container">
                    <p class="service-title">Солариум</p>
                    <p class="service-description">Освен красив загар, умереното използване на солариум подпомага и производството на витамин D, важен за цялостното ви здравословно състояние. От нас можете да си закупите и  соларна козметика, която предпазва кожата и в същото време ускорява образуването на тен. Подарете си сияен здравословен тен, заредете се с красота!
                        </p>
                    <button class="action-button" routerLink="/services" [queryParams]="{'service': 'solarium'}">Научи повече</button>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-md-7 col-sm-12 service-content-container">
                    <p class="service-title">Миглопластика</p>
                    <p class="service-description">Това е един напълно безвреден и ефикасен метод, гарантиращ естествена визия и дълготраен ефект. Миглите се апликират към естествените една по една с помощта на хипоалергенно лепило. По този начин, се увеличава дължината и обема на миглите. В резултат на това се получава изключително изразителен поглед, за който дори не е необходима употребата на спирала. Поставени и премахнати правилно, удължителите не вредят на вашите собствени мигли.
                        </p>
                    <button class="action-button" routerLink="/services" [queryParams]="{'service': 'migloplastica'}">Научи повече</button>
                </div>
                <div class="col-lg-4 col-md-5 col-sm-12 service-image-container">
                    <div class="mobile-service-image-content-container">
                        <p class="service-title">Миглопластика</p>
                        <button class="action-button" routerLink="/services" [queryParams]="{'service': 'migloplastica'}">Научи повече</button>
                    </div>
                    <div style="background-image: url('../../../assets/services_bg/migoplastica.jpg');" class="service-image">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-5 col-sm-12 service-image-container">
                    <div class="mobile-service-image-content-container">
                        <p class="service-title">Микроблейдинг</p>
                        <button class="action-button" routerLink="/services" [queryParams]="{'service': 'microblading'}">Научи повече</button>
                    </div>
                    <div style="background-image: url('../../../assets/services_bg/microblading.jpg');" class="service-image">
                    </div>
                </div>
                <div class="col-lg-8 col-md-7 col-sm-12 service-content-container">
                    <p class="service-title">Микроблейдинг</p>
                    <p class="service-description">Ако имаш неправилна форма, липсващо окосмяване или редки вежди, този дълготраен метод ще ти помогне да постигнеш красотата, която търсиш. Микроблейдинг се нарича ръчната техника, при която с фино одраскване се вкарва пигмент в епидермиса. Рисува се всяко косъмче и веждите изглеждат напълно естествено.  На усет процедурата не е болезнена. За оптимален ефект се прави ретуш до 40дни след процедурата. Трайност между 1-3 години.
                        </p>
                    <button class="action-button" routerLink="/services" [queryParams]="{'service': 'microblading'}">Научи повече</button>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-md-7 col-sm-12 service-content-container">
                    <p class="service-title">Грим</p>
                    <p class="service-description">Beauty Studio Desita може да се погрижи за цялостната подготовка за Вашия празник с пакет от услуги: прическа, маникюр и грим. Гримът е онази довършителна щриха, която перфектно ще завърши Вашата бляскава визия!
                        </p>
                    <button class="action-button" routerLink="/services" [queryParams]="{'service': 'grim'}">Научи повече</button>
                </div>
                <div class="col-lg-4 col-md-5 col-sm-12 service-image-container">
                    <div class="mobile-service-image-content-container">
                        <p class="service-title">Грим</p>
                        <button class="action-button" routerLink="/services" [queryParams]="{'service': 'grim'}">Научи повече</button>
                    </div>
                    <div style="background-image: url('../../../assets/services_bg/grim.jpg');" class="service-image">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="for-us-container">
        <p class="medium-title black-text">Свържете се с нас</p>
        <div id="map"></div>
        <div class="container">
            <div class="column-content">
                <p class="column-title">Тел. номер</p>
                <p class="column-description">098 822 5036</p>
            </div>
            <div class="column-content">
                <p class="column-title">Адрес</p>
                <p class="column-description">ж.к. Люлин Бул. Джавахарлал Неру №31, София</p>
            </div>
            <div class="column-content">
                <p class="column-title">Работно време</p>
                <div class="working-times">
                    <p class="column-description">пн: 9:00 - 20:00</p>
                    <p class="column-description">вт: 9:00 - 20:00</p>
                    <p class="column-description">ср: 9:00 - 20:00</p>
                    <p class="column-description">чт: 9:00 - 20:00</p>
                    <p class="column-description">пт: 9:00 - 20:00</p>
                    <p class="column-description">сб: 9:00 - 16:00</p>
                    <p class="column-description">нд: почивен ден</p>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-content">
        <p class="footer-text">&#169;&nbsp;2024 Десита 94 ЕООД. Всички права запазени.</p>
        <a href="https://primego.bg" target="_blank" class="designed-by-link">
            <p class="designer-text">Дизайн от <p> 
            <div style="background-image: url('../../../assets/Primego_Logo Blue.png');" class="designed-by-image">
            </div>
        </a>
    </div>
</div>