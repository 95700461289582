import { HttpParams } from '@angular/common/http';
import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  constructor(private router: Router) { }

  service: string = undefined;
  services = ["friziorstvo", "grim", "manikiur-i-pedikiur", "kozmetika", "solarium", "lazerna-epilaciq", "microblading", "migloplastica", "izvaivane-na-tqloto"];

  loadPage: boolean = false;

  ngOnInit(): void {
    this.getArticleUrl();
  }

  getArticleUrl(): void {
    const url = window.location.href;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      this.service = httpParams.get('service');
      if(this.services.includes(this.service)) {
        return;
      }
    }
    this.service = 'friziorstvo';
  }

  setArticleUrl(service: string): void {
    const url = window.location.href;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      httpParams.set('service', service);
    }
  }

  loadArticle(service: string): void {
    (this.services.includes(service)) ? this.service = service : this.service = "friziorstvo";
    this.setArticleUrl(this.service);
  }

  scrollToTop(): void {
    
  }

}
