<div class="navbars-container">
    <div class="small-navbar">
        <div class="left-container">
            <a href="tel:098-822-5036" class="small-navbar-text"><span class="material-icons">call</span><span class="small-navbar-value">098 822 5036</span><span class="small-navbar-mobile-value">098 822 5036</span></a>
            <a href="http://maps.google.com/?q=ж.к. Люлин Бул. Джавахарлал Неру №31, София" target="_blank" class="small-navbar-text"><span class="material-icons">location_on</span><span class="small-navbar-value">ж.к. Люлин Бул. Дж. Неру №31 (бл.769), София</span><span class="small-navbar-mobile-value">Бул. Дж. Неру №31 (бл.769), София</span></a>
        </div>
        <div class="right-container">
           <a href="https://www.facebook.com/BeautyStudioDesita" target="_blank"><p class="small-navbar-text"><span class="material-icons">facebook</span></p></a>
           <a href="https://www.instagram.com/studio.desita/" target="_blank"><p class="small-navbar-text"><i class="fab fa-instagram"></i></p></a>
        </div> 
    </div>
    <div class="navbar">
        <div class="left-container" routerLink="/">
            <img src="../../../assets/logo.png" class="logo" />
            <p class="logo-title">Beauty Studio Desita</p>
        </div>
        <div class="right-container">
            <p class="navbar-text" routerLink="/" [ngClass]="{'active-navbar-text': (route == '/home' || route == '/')}">Начало</p>
            <p class="navbar-text" (click)="openServices()" [ngClass]="{'active-navbar-text': route == '/services'}">Услуги</p>
            <p class="navbar-text" routerLink="/gallery" [ngClass]="{'active-navbar-text': route == '/gallery'}">Галерия</p>
        </div>
    </div>
</div>
<div class="mobile-services-selector" *ngIf="showMobileServicesSelector" [@panelInOut]>
    <div class="mobile-services-topbar">
        <p class="mobile-services-selector-title">Изберете услуга</p>
        <i class="material-icons" (click)="showMobileServicesSelector = false">close</i>
    </div>
    <div class="mobile-services-links">
        <a class="mobile-services-link" (click)="openService('friziorstvo')">Фризьорство</a>
        <a class="mobile-services-link" (click)="openService('kozmetika')">Козметика</a>
        <a class="mobile-services-link" (click)="openService('manikiur-i-pedikiur')">Маникюр/Педикюр</a>
        <a class="mobile-services-link" (click)="openService('lazerna-epilaciq')">Лазерна епилация</a>
        <a class="mobile-services-link" (click)="openService('solarium')">Солариум</a>
        <a class="mobile-services-link" (click)="openService('migloplastica')">Миглопластика</a>
        <a class="mobile-services-link" (click)="openService('microblading')">Микроблейдинг</a>
        <a class="mobile-services-link" (click)="openService('grim')">Грим</a>
        <a class="mobile-services-link" (click)="openService('izvaivane-na-tqloto')">Извайване на тялото</a>
    </div>
</div>