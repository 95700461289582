<app-navbar></app-navbar>
<div class="main-section">
  <div class="container">
    <div class="row">
      <div class="col-lg-3">
        <div class="navigation-container">
          <p class="navigation-container-title">Услуги</p>
          <div class="navigation-links-container">
            <a
              (click)="loadArticle('friziorstvo')"
              class="navigation-link"
              [ngClass]="{ 'active-navigation-link': service == 'friziorstvo' }"
              >Фризьорство</a
            >
            <a
              (click)="loadArticle('kozmetika')"
              class="navigation-link"
              [ngClass]="{ 'active-navigation-link': service == 'kozmetika' }"
              >Козметика</a
            >
            <a
              (click)="loadArticle('manikiur-i-pedikiur')"
              class="navigation-link"
              [ngClass]="{
                'active-navigation-link': service == 'manikiur-i-pedikiur'
              }"
              >Маникюр и педикюр</a
            >
            <a
              (click)="loadArticle('lazerna-epilaciq')"
              class="navigation-link"
              [ngClass]="{
                'active-navigation-link': service == 'lazerna-epilaciq'
              }"
              >Лазерна епилация</a
            >
            <a
              (click)="loadArticle('solarium')"
              class="navigation-link"
              [ngClass]="{ 'active-navigation-link': service == 'solarium' }"
              >Солариум</a
            >
            <a
              (click)="loadArticle('migloplastica')"
              class="navigation-link"
              [ngClass]="{
                'active-navigation-link': service == 'migloplastica'
              }"
              >Миглопластика</a
            >
            <a
              (click)="loadArticle('microblading')"
              class="navigation-link"
              [ngClass]="{
                'active-navigation-link': service == 'microblading'
              }"
              >Микроблейдинг</a
            >
            <a
              (click)="loadArticle('grim')"
              class="navigation-link"
              [ngClass]="{ 'active-navigation-link': service == 'grim' }"
              >Грим</a
            >
            <a
              (click)="loadArticle('izvaivane-na-tqloto')"
              class="navigation-link"
              [ngClass]="{
                'active-navigation-link': service == 'izvaivane-na-tqloto'
              }"
              >Извайване на тялото</a
            >
          </div>
          <button class="action-button" routerLink="/">Запази час</button>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="loaded-article" *ngIf="service == 'friziorstvo'">
          <p class="loaded-article-title">Фризьорство</p>
          <p class="loaded-article-description">
            Здравата коса е красива коса! Екипът ни от трима професионални
            коафьори ще ви предложи конкретна насоченост според Вашите
            изисквания. Салонът работи с висококачествените продукти на марките
            Christian of Roma, Milkshake, Alfaparf, Papino, Nook и други. Ние
            вярваме, че занаята, с който се занимаваме, изисква отдаденост на
            100%, непрекъснато обучение и усъвършенстване. Искаме да дадем
            най-доброто на нашите клиенти, те са специални за нас!
          </p>
          <div class="loaded-article-section">
            <hr />
            <div class="loaded-article-min-section">
              <p class="loaded-article-min-section-title">Подстригване</p>
              <div class="container">
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Дамско - 25лв.
                    </p>
                  </div>
                  <div class="col">
                    <p class="loaded-article-min-section-text">Мъжко - 20лв.</p>
                  </div>
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Детско - 18лв.
                    </p>
                  </div>
                </div>
              </div>
              <p class="loaded-article-min-section-text-description">
                СУХО ПОДСТРИГВАНЕ - Това е техника, чрез която се подстригват
                само цъфналите краища, а дължината и обемът на косата се
                запазват. Процедурата се извършва изцяло ръчно.<span
                  class="loaded-article-min-section-text"
                >
                  - 30лв. с включен стайлинг</span
                >
              </p>
            </div>
            <hr />
            <div class="loaded-article-min-section">
              <p class="loaded-article-min-section-title">
                Стайлинг със сешоар
              </p>
              <div class="container">
                <p class="loaded-article-min-section-subtitle">Изправяне</p>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Къса коса - 15лв.
                    </p>
                  </div>
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Средно-къса коса - 18лв.
                    </p>
                  </div>
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Средно-дълга коса - 20лв.
                    </p>
                  </div>
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Дълга коса - 25/40лв.
                    </p>
                  </div>
                </div>
                <p class="loaded-article-min-section-subtitle">На букли</p>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Средно-къса коса - 20лв.
                    </p>
                  </div>
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Средно-дълга коса - 25лв.
                    </p>
                  </div>
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Дълга коса - 35/45лв.
                    </p>
                  </div>
                </div>
                <p class="loaded-article-min-section-subtitle">Кичури</p>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Кичури с шапка или фолио - 80лв./200лв. (в зависимост от
                      косата)
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Кичури с фолио 2 цвята - 90лв/230лв. (в зависимост от
                      косата)
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Частични кичури - 50лв.
                    </p>
                  </div>
                </div>
                <p class="loaded-article-min-section-subtitle">Други</p>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Изсушаване с дифузер - 16лв/25лв. (в зависимост от косата)
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Навиване с маша - 20лв/40лв. (в зависимост от косата)
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Ретро преса - 25лв/35лв. (в зависимост от косата)
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Вафлички в корена - 5лв.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Официална прическа - 60лв/100лв. (в зависимост от косата)
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Омбре, балеаж - 130лв/250лв. (в зависимост от косата)
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Вечерна прическа - 50лв.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Пробна прическа - 50лв.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="loaded-article-min-section">
              <p class="loaded-article-min-section-title">Боядисване</p>
              <div class="container">
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">Къса - 45лв.</p>
                  </div>
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Средна - 60лв.
                    </p>
                  </div>
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Дълга - 70лв/100лв. (в зависимост от косата)
                    </p>
                  </div>
                </div>
                <p class="loaded-article-min-section-text-description">
                  Добавка органичен бразилски кератин към боята – 10лв. -
                  предпазва косата от химически увреждания, прави цвета по- ярък
                  и траен.
                </p>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Корени - 50лв.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      С боя на клиента - 25лв/40лв. (в зависимост от косата)
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Матиране - 25лв/45лв. (в зависимост от косата)
                    </p>
                  </div>
                </div>
                <p class="loaded-article-min-section-subtitle">Изрусяване</p>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Къса коса - 50лв.
                    </p>
                  </div>
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Средна коса - 80лв.
                    </p>
                  </div>
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Дълга коса - 120лв.
                    </p>
                  </div>
                </div>
                <p class="loaded-article-min-section-subtitle">Плитки</p>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Къса коса - 3лв.
                    </p>
                  </div>
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Средна коса - 5лв.
                    </p>
                  </div>
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Дълга коса - 8лв.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="loaded-article-min-section">
              <p class="loaded-article-min-section-title">Терапии</p>
              <div class="container">
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Терапия за тотално възстановяване – 40лв/80лв.( според
                      косата)
                    </p>
                    <p class="loaded-article-min-section-text-description">
                      Осигурява незабавно възстановяване и дълготрайно
                      хидратиране за силно увредена и химически третирана коса.
                      Видими резултати до 12 измивания след процедурата.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Терапия за цъфтящи краища – 30лв./60лв. (според косата)
                    </p>
                    <p class="loaded-article-min-section-text-description">
                      Незабавно възстановяване на накъсана коса, действайки
                      върху вътрешната структура на косата като запълва и
                      свързва повредените влакна. Активира защитна термо бариера
                      около косъма, която предпазва от бъдещо химическо,
                      топлинно или механично въздействие. В процедурата е
                      включен стайлинг.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Кератинова терапия Estel – 30/50лв.(според косата)
                    </p>
                    <p class="loaded-article-min-section-text-description">
                      Чрез дълбокото проникване на кератин в косъма, косата
                      става бляскава, здрава, обемна, мека и лесна за
                      разресване. Успокоява скалпа.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Оцветяваща терапия –25/40лв. (според косата)
                    </p>
                    <p class="loaded-article-min-section-text-description">
                      Баланс на тоновете между две боядисвания. Подхранва
                      косата, изпълва я с блясък и ѝ придава наситен, жив цвят.
                      Същевременно защитава косата от ежедневните външни
                      агресори и я оставя кадифено мека и блестяща.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Филър терапия–40/80лв.(според косата)
                    </p>
                    <p class="loaded-article-min-section-text-description">
                      Подходяща за тънка, изтощена и суха коса. Комплексното
                      действие на трите продукта от терапията допълва, активира
                      и запечатва активните агенти в косъма и запазва за дълго
                      ефекта от терапията в салона.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Перманентно къдрене – 60/150лв.( според косата)
                    </p>
                    <p class="loaded-article-min-section-text-description">
                      Процедурата променя правата коса в къдрава/ на вълни.
                      Трайното къдрене е пластична операция на косата и води до
                      промяна на текстурата и структурата на косъма, с
                      дълготраен ефект.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Трайно изправяне с кератин – 150/250лв.( според косата)
                    </p>
                    <p class="loaded-article-min-section-text-description">
                      Терапията, гарантира перфектно изправяне на косата и
                      постигане на гладкост без използването на парабени и
                      формалдехид. Подходяща за всички типове коса, с дълготраен
                      ефект до 6 месеца.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Заглаждаща терапия – 100/150лв.( според косата)
                    </p>
                    <p class="loaded-article-min-section-text-description">
                      Чрез насищане на косъм с кератин и арган, косата се
                      заглажда и става по-гъста, мека и блестяща. Ефектът е
                      мигновен и дълготраен, което прави стилизираното в домашни
                      условия много лесно. Терапията е подходяща за чупливи и
                      непокорни коси.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="loaded-article" *ngIf="service == 'grim'">
          <p class="loaded-article-title">Грим</p>
          <p class="loaded-article-description">
            Beauty Studio Desita може да се погрижи за цялостната подготовка за
            Вашия празник с пакет от услуги: прическа, маникюр и грим. Гримът е
            онази довършителна щриха, която перфектно ще завърши Вашата бляскава
            визия!
          </p>
          <div class="loaded-article-section">
            <hr />
            <div class="loaded-article-min-section">
              <div class="container">
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Дневен - 50лв.
                    </p>
                  </div>
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Официален - 70лв.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="loaded-article" *ngIf="service == 'manikiur-i-pedikiur'">
          <p class="loaded-article-title">Маникюр и педикюр</p>
          <p class="loaded-article-description">
            Перфектните маникюр и педикюр са визитката на една дама! Независимо
            дали ще се спрете на класически цветове, френски маникюр или пък на
            ефектни декорации, изграждане с гел – от нас ще си тръгнете с
            настроение и самочувствие. А безупречните резултати са гарантирани
            от партньорите ни KODI,BLUESKY,SNB и GEL.IT
          </p>
          <div class="loaded-article-section">
            <hr />
            <div class="loaded-article-min-section">
              <p class="loaded-article-min-section-title">Маникюр</p>
              <div class="container">
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Обикновен маникюр с лак – 20лв.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Апаратен маникюр с гел лак + сваляне – 35лв.
                    </p>
                  </div>
                </div>
                <p class="loaded-article-min-section-subtitle">
                  Поставяне на гел лак върху естествен нокът
                </p>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      С гел лак – 45лв.
                    </p>
                  </div>
                </div>
                <p class="loaded-article-min-section-subtitle">
                  Изграждане с гел
                </p>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">Къси – 60лв.</p>
                  </div>
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Средни – 80лв.
                    </p>
                  </div>
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Дълги – 100лв.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Поправка на 1 счупен нокът – 5лв.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Декорация на нокът – 1/3лв.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="loaded-article-min-section">
              <p class="loaded-article-min-section-title">Педикюр</p>
              <p class="loaded-article-min-section-text-description">
                Оформяне на нокътната плочка, ваничка, избутване и почистване на
                кутикули, премахване на загрубяла кожа по стъпалото, полагане на
                лак, ексфолиращ крем
              </p>
              <div class="container">
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      С обикновен лак – 35лв.
                    </p>
                  </div>
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      С гел лак – 45лв.
                    </p>
                  </div>
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Без лак – 30лв.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="loaded-article-min-section">
              <p class="loaded-article-min-section-title">Частичен педикюр</p>
              <p class="loaded-article-min-section-text-description">
                Оформяне на нокътната плочка, избутване и почистване на
                кутикули, полагане на лак, ексфолиращ крем
              </p>
              <div class="container">
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      С обикновен лак – 20лв.
                    </p>
                  </div>
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      С гел лак – 30лв.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="loaded-article" *ngIf="service == 'kozmetika'">
          <p class="loaded-article-title">Козметика</p>
          <p class="loaded-article-description">
            Кожата е най-големият орган от човешкото тяло. Изисква специално
            отношение и внимателно третиране. Да се грижиш за кожата е
            отговорност, която ние в Beauty Studio Desita приемаме много
            сериозно!
          </p>
          <div class="loaded-article-section">
            <hr />
            <div class="loaded-article-min-section">
              <p class="loaded-article-min-section-title">Общи</p>
              <div class="container">
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Почистване на лице (дегримиране, пилинг, вапозон, ръчна
                      екстракция, дарсонвал, маска, крем) 60мин.– 45лв.
                    </p>
                  </div>
                </div>
                <p class="loaded-article-min-section-subtitle">
                  Биостимулиращ и ревитализиращ пилинг BioRePeel
                </p>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">Лице – 80лв.</p>
                  </div>
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Лице и шия – 110лв.
                    </p>
                  </div>
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Деколте – 90лв.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Шоколадова терапия – 30лв.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Биолифтинг терапия с ектстракт от лакесис – 30лв.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Хидратираща терапия за суха кожа – 30лв.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Терапия околоочен контур 20мин.- 20лв.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Кислородна терапия с лифтинг ефект 30мин. – 40лв.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Диамантено микродермабразио 40мин. – 40лв.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Кислородна терапия + Микродермабразио – 70лв.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Радиочестотен лифтинг – 40лв.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Парафинова терапия 60мин.- 35лв.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Хидро-масаж под пара 40мин. – 30лв.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Хигиенно-козм. масаж на лице, шия и деколте 30мин.- 30лв.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Втриване на ампула с ултразвук – 20лв.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="loaded-article-min-section">
              <p class="loaded-article-min-section-title">Кола маска</p>
              <div class="container">
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text underline">
                      За жени:
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Цели крака - 25лв.
                    </p>
                  </div>
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      1/2 крака - 15лв.
                    </p>
                  </div>
                  <div class="col">
                    <p class="loaded-article-min-section-text">Ръце - 15лв.</p>
                  </div>
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      1/2 ръце - 10лв.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Мишници - 10лв.
                    </p>
                  </div>
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Почистване и оформяне на вежди - 10лв.
                    </p>
                  </div>
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      + боя на вежди - 5лв.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Пълен интим - 20лв.
                    </p>
                  </div>
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Бикини зона - 10лв.
                    </p>
                  </div>
                  <div class="col">
                    <p class="loaded-article-min-section-text">Мустак - 3лв.</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Брадичка - 3лв.
                    </p>
                  </div>
                  <div class="col">
                    <p class="loaded-article-min-section-text">Лице - 8лв.</p>
                  </div>
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Корем/кръст - 7лв.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">Лице - 8лв.</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text underline">
                      За мъже:
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Цели крака - 35лв.
                    </p>
                  </div>
                  <div class="col">
                    <p class="loaded-article-min-section-text">Ръце - 25лв.</p>
                  </div>
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Мишници - 15лв.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Почистване и оформяне на вежди - 10лв.
                    </p>
                  </div>
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Гръб/гърди - 30лв.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="loaded-article-min-section">
              <p class="loaded-article-min-section-title">Масаж</p>
              <div class="container">
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Гръб 30мин. – 25лв.
                    </p>
                  </div>
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Яка и раменен пояс 20мин. – 20лв.
                    </p>
                  </div>
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Антицелулитен масаж 40мин. – 40лв.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="loaded-article" *ngIf="service == 'solarium'">
          <p class="loaded-article-title">Солариум</p>
          <p class="loaded-article-description">
            Освен красив загар, умереното използване на солариум подпомага и
            производството на витамин D, важен за цялостното ви здравословно
            състояние. От нас можете да си закупите и соларна козметика, която
            предпазва кожата и в същото време ускорява образуването на тен.
            Подарете си сияен здравословен тен, заредете се с красота!
          </p>
          <div class="loaded-article-section">
            <hr />
            <div class="loaded-article-min-section">
              <p class="loaded-article-min-section-title">Свободно посещение</p>
              <div class="container">
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">1лв./мин.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="loaded-article" *ngIf="service == 'lazerna-epilaciq'">
          <p class="loaded-article-title">Лазерна епилация</p>
          <p class="loaded-article-description">
            Работим с диоден лазер от най-висок клас и процедурите са ефективни
            за всички фототипове кожа, и напълно безопасни по всяко време на
            годината. Диодната лазерна епилация е доказано най-ефективният метод
            за трайно обезкосмяване.
          </p>
          <div class="loaded-article-section">
            <hr />
            <div class="loaded-article-min-section">
              <div class="container">
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text underline">
                      Процедурата е:
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="tick-container">
                      <i class="material-icons">label_important</i>
                      <p class="loaded-article-min-section-text">
                        Напълно безболезнена
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="tick-container">
                      <i class="material-icons">label_important</i>
                      <p class="loaded-article-min-section-text">
                        Ефективна за всички фототипове кожа
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="tick-container">
                      <i class="material-icons">label_important</i>
                      <p class="loaded-article-min-section-text">
                        Безопасна по всяко време на годината
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="tick-container">
                      <i class="material-icons">label_important</i>
                      <p class="loaded-article-min-section-text">
                        Има ефект още след първата процедура
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="tick-container">
                      <i class="material-icons">label_important</i>
                      <p class="loaded-article-min-section-text">
                        Подходяща за всички зони
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="tick-container">
                      <i class="material-icons">label_important</i>
                      <p class="loaded-article-min-section-text">
                        Доказано дълготрайни резултати
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text underline">
                      Ето какво трябва да знаем преди да се подложим на
                      процедурата:
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="tick-container">
                      <i class="material-icons">check_circle_outline</i>
                      <p class="loaded-article-min-section-text">
                        ️Космите да НЕ бъдат махани от корен с кола маска или
                        епилатор поне три-четири седмици преди процедурата.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="tick-container">
                      <i class="material-icons">check_circle_outline</i>
                      <p class="loaded-article-min-section-text">
                        Нужни са около 6-9 процедури, за да се премахне
                        окосмяването и ефектът да се задържи за дълго време,
                        като точният брой процедури е индивидуален за всеки
                        клиент.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="tick-container">
                      <i class="material-icons">check_circle_outline</i>
                      <p class="loaded-article-min-section-text">
                        Преди да отидете на лазерна епилация, космите трябва да
                        са обръснати.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="tick-container">
                      <i class="material-icons">check_circle_outline</i>
                      <p class="loaded-article-min-section-text">
                        Интервалът между процедурите не трябва да е по-малко от
                        35-90 дни, като специалистът, който работи с вас,
                        най-добре ще може да ви каже за вашата кожа и окосмяване
                        колко трябва да е този интервал.
                      </p>
                    </div>
                  </div>
                </div>
                <p class="loaded-article-min-section-text-description">
                  За всякакви допълнителни въпроси, които ви притесняват, се
                  насочете към наш специалист на телефонният ни номер -
                  098-822-5036
                </p>
                <div class="row">
                  <div class="col">
                    <img
                      src="../../../assets/lazerna-epilaciq-ceni.png"
                      class="row-image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="loaded-article" *ngIf="service == 'microblading'">
          <p class="loaded-article-title">Микроблейдинг</p>
          <p class="loaded-article-description">
            Ако имаш неправилна форма, липсващо окосмяване или редки вежди, този
            дълготраен метод ще ти помогне да постигнеш красотата, която търсиш.
            Микроблейдинг се нарича ръчната техника, при която с фино одраскване
            се вкарва пигмент в епидермиса. Рисува се всяко косъмче и веждите
            изглеждат напълно естествено. На усет процедурата не е болезнена. За
            оптимален ефект се прави ретуш до 40дни след процедурата. Трайност
            между 1-3 години.
          </p>
          <div class="loaded-article-section">
            <hr />
            <div class="loaded-article-min-section">
              <p class="loaded-article-min-section-title">Цена</p>
              <div class="container">
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      250лв. с включен ретуш
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="loaded-article" *ngIf="service == 'migloplastica'">
          <p class="loaded-article-title">Миглопластика</p>
          <p class="loaded-article-description">
            Това е един напълно безвреден и ефикасен метод, гарантиращ
            естествена визия и дълготраен ефект. Миглите се апликират към
            естествените една по една с помощта на хипоалергенно лепило. По този
            начин, се увеличава дължината и обема на миглите. В резултат на това
            се получава изключително изразителен поглед, за който дори не е
            необходима употребата на спирала. Поставени и премахнати правилно,
            удължителите не вредят на вашите собствени мигли.
          </p>
          <div class="loaded-article-section">
            <hr />
            <div class="loaded-article-min-section">
              <p class="loaded-article-min-section-title">
                Поддръжка на мигли:
              </p>
              <div class="container">
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      1. Първите 24часа миглите не се мокрят, гримират, не се
                      ходи на сауна, солариум,не се спи по корем, за да не се
                      измачкат.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      2. Лицето се чисти с мицеларна вода или гел на водна
                      основа с тампон внимателно около миглите.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      2. Лицето се чисти с мицеларна вода или гел на водна
                      основа с тампон внимателно около миглите.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      3. Миглите не се мажат с нищо мазно, за да не се разгради
                      лепилото.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      4. При вземане на душ, струята да не ги удря директно.
                      Подсушете ги внимателно, без да се търкат.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      5. Не се опитвайте да махате миглите си сами!
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      6. Препоръчително е поддръжка да се прави на 2-4 седмици.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="loaded-article-min-section">
              <p class="loaded-article-min-section-title">Цени</p>
              <div class="container">
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Първоначално поставяне на мигли косъм по косъм – 70лв.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Поддръжка на мигли косъм по косъм – 40лв.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Първоначално поставяне на обемни мигли – 100лв.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Поддръжка на обемни мигли – 50лв.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="loaded-article-min-section-text">
                      Отстраняване на миглопластика – 15лв.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="loaded-article" *ngIf="service == 'izvaivane-na-tqloto'">
          <p class="loaded-article-title">
            Извайване на тялото с Fotora Super Shape
          </p>
          <p class="loaded-article-description">
            Fotora Super Shape е НАЙ-ДОБРАТА комбинация от свръх мощен
            медицински клас Кавитация, Мултиполярна радиочестота и Вакуум
          </p>
          <p class="loaded-article-description">
            ☑️ДОКАЗАНИ РЕЗУЛТАТИ за редукция на целулит и извайване на тялото!
          </p>
          <p class="loaded-article-description">
            ➡️МУЛТИПОЛЯРЕН RF - активно стимулира разграждането на мазнините;
            провокира заздравяването на колагеновите влакна; загрява кожата и
            стимулира вътреклетъчното разпространение на кислород; използва
            червени PRD светлини, които засилват ефекта на RF технологията.
          </p>
          <p class="loaded-article-description">
            ➡️ВАКУУМ - стимулира лимфата; разгражда и редуцира подкожния слой
            мазнини; оформя контурната линия на тялото или лицето; масажира и
            релаксира тъканите; засилва кръвообращението и улеснява доставянето
            на кислород в клетките.
          </p>
          <p class="loaded-article-description">
            ➡️КАВИТАЦИЯ - разгражда мазнините, като достига дълбоко в областта
            на подкожната мастна тъкън; терапията е напълно безопасна, високо
            ефективна и не предизвиква дискомфорт.
          </p>
          <p class="loaded-article-description">
            ✅Намаляване на обиколката до 2см. на процедура, намаляване на
            целулита, безболезнено и с трайни резултати.
          </p>
          <div class="loaded-article-section">
            <hr />
            <div class="loaded-article-min-section">
              <div class="container">
                <div class="row">
                  <div class="col">
                    <img src="../../../assets/fotora.jpg" class="row-image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="footer-content">
  <p class="footer-text">&#169;&nbsp;2024 Десита 94 ЕООД. Всички права запазени.</p>
  <a href="https://primego.bg" target="_blank" class="designed-by-link">
      <p class="designer-text">Дизайн от <p> 
      <div style="background-image: url('../../../assets/Primego_Logo Blue.png');" class="designed-by-image">
      </div>
  </a>
</div>
