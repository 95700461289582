<app-navbar></app-navbar>
<div class="main-section">
    <div class="container">
        <p class="loaded-article-title">Фризьорство</p>
        <div class="row">
            <div class="col-md-6">
                <div style="background-image: url('../../../assets/services_bg/frizior.jpg')" class="col-image"></div>
            </div>
            <div class="col-md-6">
                <div style="background-image: url('../../../assets/services_bg/viber_image_2021-03-09_15-37-25.jpg')" class="col-image"></div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div style="background-image: url('../../../assets/services_bg/viber_image_2021-03-09_15-37-26.jpg')" class="col-image align-to-top col-image-high"></div>
            </div>
            <div class="col-md-4">
                <div style="background-image: url('../../../assets/services_bg/photographies/hairdressing/2.jpg')" class="col-image align-to-top col-image-high"></div>
            </div>
            <div class="col-md-4">
                <div style="background-image: url('../../../assets/services_bg/photographies/hairdressing/3.jpg')" class="col-image align-to-top col-image-high"></div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div style="background-image: url('../../../assets/services_bg/photographies/hairdressing/6.jpg')" class="col-image col-image-landscape big-landscape-mobile"></div>
            </div>
            <div class="col-md-6">
                <div style="background-image: url('../../../assets/services_bg/photographies/hairdressing/4.jpg')" class="col-image col-image-landscape"></div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div style="background-image: url('../../../assets/services_bg/photographies/hairdressing/5.jpg')" class="col-image align-to-top col-image-middle"></div>
            </div>
            <div class="col-md-6">
                <div style="background-image: url('../../../assets/services_bg/photographies/hairdressing/7.jpg')" class="col-image align-to-top"></div>
            </div>
        </div>
        <p class="loaded-article-title">Козметика</p>
        <div class="row">
            <div class="col-md-6">
                <div style="background-image: url('../../../assets/services_bg/kozmetika.jpg')" class="col-image-landscape"></div>
            </div>
            <div class="col-md-6">
                <div style="background-image: url('../../../assets/services_bg/viber_image_2021-03-09_15-38-22.jpg')" class="col-image-landscape"></div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div style="background-image: url('../../../assets/services_bg/photographies/cosmetics/1.jpg')" class="col-image-landscape align-to-top"></div>
            </div>
            <div class="col-md-6">
                <div style="background-image: url('../../../assets/services_bg/photographies/cosmetics/2.jpg')" class="col-image-landscape"></div>
            </div>
        </div>
        <p class="loaded-article-title">Маникюр/педикюр</p>
        <div class="row">
            <div class="col-md-6">
                <div style="background-image: url('../../../assets/services_bg/manikur.jpg')" class="col-image"></div>
            </div>
            <div class="col-md-6">
                <div style="background-image: url('../../../assets/services_bg/viber_image_2021-03-09_15-37-43.jpg')" class="col-image-landscape-half"></div>
                <div style="background-image: url('../../../assets/services_bg/pedikur.jpg')" class="col-image-landscape-half"></div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div style="background-image: url('../../../assets/services_bg/photographies/manikur/1.jpg')" class="col-image"></div>
            </div>
            <div class="col-md-4">
                <div style="background-image: url('../../../assets/services_bg/photographies/manikur/2.jpg')" class="col-image"></div>
            </div>
            <div class="col-md-4">
                <div style="background-image: url('../../../assets/services_bg/photographies/manikur/4.jpg')" class="col-image"></div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div style="background-image: url('../../../assets/services_bg/photographies/manikur/3.jpg')" class="col-image"></div>
            </div>
            <div class="col-md-6">
                <div style="background-image: url('../../../assets/services_bg/photographies/manikur/5.jpg')" class="col-image"></div>
            </div>
        </div>
        <p class="loaded-article-title">Лазерна епилация</p>
        <div class="row">
            <div class="col-md-6">
                <div style="background-image: url('../../../assets/services_bg/lazerna_epilaciq.jpg')" class="col-image-landscape"></div>
            </div>
            <div class="col-md-6">
                <div style="background-image: url('../../../assets/services_bg/viber_image_2021-03-09_15-39-39.jpg')" class="col-image-landscape"></div>
            </div>
        </div>
        <p class="loaded-article-title">Миглопластика, солариум и микроблейдинг</p>
        <div class="row">
            <div class="col-md-6">
                <div style="background-image: url('../../../assets/services_bg/migoplastica.jpg')" class="col-image"></div>
            </div>
            <div class="col-md-6">
                <div style="background-image: url('../../../assets/services_bg/photographies/solarium/1.jpg')" class="col-image"></div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div style="background-image: url('../../../assets/services_bg/photographies/microblading/1.jpg')" class="col-image"></div>
            </div>
            <div class="col-md-4">
                <div style="background-image: url('../../../assets/services_bg/solarium.jpg')" class="col-image"></div>
            </div>
            <div class="col-md-4">
                <div style="background-image: url('../../../assets/services_bg/photographies/lashesplastic/1.jpg')" class="col-image"></div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div style="background-image: url('../../../assets/services_bg/microblading.jpg')" class="col-image"></div>
            </div>
            <div class="col-md-6">
                <div style="background-image: url('../../../assets/services_bg/photographies/lashesplastic/2.jpg')" class="col-image col-image-landscape-half big-landscape-mobile"></div>
                <div style="background-image: url('../../../assets/services_bg/photographies/lashesplastic/5.jpg')" class="col-image col-image-landscape-half big-landscape-mobile"></div>
            </div>
        </div>
        <p class="loaded-article-title">Грим</p>
        <div class="row">
            <div class="col-md-6">
                <div style="background-image: url('../../../assets/services_bg/grim.jpg')" class="col-image align-to-top"></div>
            </div>
            <div class="col-md-6">
                <div style="background-image: url('../../../assets/services_bg/viber_image_2021-03-09_15-38-09.jpg')" class="col-image align-to-top"></div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div style="background-image: url('../../../assets/services_bg/photographies/makeup/1.jpg')" class="col-image align-to-top"></div>
            </div>
            <div class="col-md-6">
                <div style="background-image: url('../../../assets/services_bg/photographies/makeup/2.jpg')" class="col-image align-to-top"></div>
            </div>
        </div>
    </div>
</div>
<div class="footer-content">
    <p class="footer-text">&#169;&nbsp;2024 Десита 94 ЕООД. Всички права запазени.</p>
    <a href="https://primego.bg" target="_blank" class="designed-by-link">
        <p class="designer-text">Дизайн от <p> 
        <div style="background-image: url('../../../assets/Primego_Logo Blue.png');" class="designed-by-image">
        </div>
    </a>
  </div>